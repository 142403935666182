// ================================================================================
// ClipboardClass
// ================================================================================
var ClipboardClass = (function() {

 
    function copyText(text) {
        // Create temp element off-screen to hold text.
        var tempElem = $('<textarea style="position: absolute; top: -8888px; left: -8888px">');
        $("body").append(tempElem);

        tempElem.val(text).select();
        document.execCommand("copy");
        tempElem.remove();
    }


    // ============================================================================
    // Class API
    // ============================================================================
    return {
        copyText: copyText
    };
})();

